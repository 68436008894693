header{
    width: 100vw;
    font-family: Effra, Arial, serif;
    background-color: transparent;

}

.mycol {
    border: 1px solid red;
}
#top-header {
    margin-left: 5%;
    text-align: center;
}

#bottom-header {
    left: 0;
    right: 0;
    color: #fff;
    width: 100vw;
    background-color: #001E46
}

#top-header p {
    padding-top: .4rem;
    margin-top: .4rem;
    float: left;
    margin-bottom: .4rem;
    font-size: 20px;
}

.header-logo {
    float: left;
    width: 180px;
}

.header-logo-right {
    float: right;
    width: 180px;
}

#bottom-header  a {
    line-height: 19px;
    font-size: 16px;
    color: #0085CA;
    font-weight: 700px;
    border: "2px solid green"
}

#bottom-header ul li {
    display: inline-block;
    line-height: 14px;
    padding-left: .5rem;
    border-left: solid .15rem #808080
}

.delimiter {
    color: gray;
    line-height: 14px;
    font-weight: 900px;
}

#bottom-header ul li:first-child {
    margin-left: 3.3rem;
    border-left: none;
}

.ant-menu-item:hover::after{
    /*border-bottom: none !important;*/
    border-bottom: 2px solid darkblue!important;

}
.ant-menu-item-selected::after{
    /*border-bottom: none !important;*/
    border-bottom: 2px solid darkblue!important;

}
.ant-menu-horizontal {
    border-bottom:1px solid #F5941E !important;
}