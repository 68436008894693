.footer{
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    color: black;
    /*height:450px;*/
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #F5941E;
    font-family: Effra, Arial, serif;
    flex-shrink: 0;
}

.main-footer{
    margin-left: 5%;
    text-align: center;
}

.main-footer img{
    float: left;
    width: 175px;
    margin-bottom: 10px;
}

.footer-menu{
    float: right;
    margin-right: 5%;
    position: relative;
}

.footer-menu ul{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -1px;
}

.footer-menu ul li{
    padding-right: 5px;
    font-size: 16px;
    display: block;
    line-height: 1.2rem;
    flex-basis: auto;
    text-align: center;
    border-left: 1px solid #ccc;
}

.footer-menu ul li:first-child {
    border: 0
}

.footer-menu ul li a{
    color: #cfd2d6;
    text-decoration: none;
}

.footer-menu ul li a:hover{
    color: #27bcda;
}