
.page-head {
    background-size: cover;
    padding: 150px 0;
    text-align: center;
    color: white;
}

.container {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem
}
main {
    padding-top: 0.1rem;
    padding-bottom: 2rem;
    background-color: #001E46;
    display: block;
}

