.my-container{
    margin-right: 5%;
    margin-left: 5%;
    min-height: 90vh;

}
.ant-carousel .slick-slide {
    overflow: hidden;
    height: 20rem;
    background: #364d79;
}

.ant-carousel .slick-slide div {
    color: #fff;
}
.img {
    height: 40rem;
}
.ant-carousel .slick-list .slick-slide.slick-active {
    pointer-events: auto;
    height: 39.5rem;
}

.custom-section-card {
    width: 100%;
    height: 40rem;
    margin-bottom: 10px;
    /*margin-top: 15px;*/
    /*background-color: #E8E8E7;*/
    /*margin-bottom: 20px;*/
    /*border: 1px solid red;*/
}

.custom-section-card > .ant-card-body {
    padding: 5px;
    width: 100%;
    height: 40rem;
    margin-bottom: 0px;
    /*background-color:red;*/
    border: 1px solid orange;
}.custom-section-card-2 {
    width: 100%;
    /*height: 80rem;*/
    margin-bottom: 0px;
     min-height: 90vh;

     /*margin-top: 15px;*/
    /*background-color: #E8E8E7;*/
    /*margin-bottom: 20px;*/
    /*border: 1px solid red;*/
}

.custom-section-card-2 > .ant-card-body {
    padding: 5px;
    width: 100%;
    height: 80rem;
    /*margin-bottom: 20px;*/
    min-height: 100vh;
    overflow: auto;
    /*background-color:red;*/
    border: 1px solid orange;
}
.custom-section-card-image .ant-card-head{
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 24px;
    background: transparent;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;

}
.custom-section-card-image .ant-card-body {
    text-align:left;
    vertical-align: middle;
    color:black;
    font-size: 18px;
    /*display: table-cell;*/
    /*background-color: pink;*/
    height: 20rem;
    width: 100%;
    padding: 0;

}


.custom-section-cad .ant-card-head{
    min-height: 48px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 24px;
    background: transparent;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;

}
.custom-section-cad .ant-card-body {
    text-align:left;
    padding-left: 14px;
    vertical-align: middle;
    color:black;
    height: 5rem;
    font-size: 18px;
    margin-bottom: 0;
}
.carousel-caption p{
    width: 75%;
    margin: auto;
    font-size: 18px;
    line-height: 1.9;
    color:black;
}