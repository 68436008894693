@import '~antd/dist/antd.css';

body,
html {

}

body {

}

code {

}


.wrapper {
    min-height: 90vh;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.content {
    flex: 1 1 0;
    min-height: 90vh;
    overflow: hidden; /* Hide scrollbars */
}

/* You can ignore the styling below */
.app {
    font-family: sans-serif;
}
