
.boxed {
    border: 1px solid #dde7ea;
    padding: 20px;
    margin-bottom: 30px;
    height: 15rem;
}

.container {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem
}
main {
    padding-top: 0.1rem;
    padding-bottom: 2rem;
    background-color: #001E46;
    display: block;
}

